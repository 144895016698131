<script>
import { Bar, mixins } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const { reactiveData } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveData],
  props: ['data'],
  data() {
    return {
      chartData: {
        labels: [],
        datasets: []
      },
      options: {
        plugins: {
          datalabels: {
            color: '#ffffff',
            font: {
              weight: 'bold',
              size: 16
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scaleShowValues: true,
        legend: {
          labels: {
            fontColor: 'grey'
          }
        },
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true
              }
            }
          ],
          xAxes: [
            {
              stacked: true,
              autoSkip: false,
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    };
  },

  mounted() {
    this.addPlugin(ChartDataLabels);
    this.renderChart(this.chartData, this.options);
  },
  watch: {
    data(value) {
      this.chartData = {
        ...this.chartData,
        labels: value.map(i => i.checklistName),

        datasets: [
          {
            label: `Submitted: ${value.reduce((i, j) => i + j.submittedcount, 0)}`,
            backgroundColor: 'Green',
            data: value.map(i => i.submittedcount),
            hoverBackgroundColor: 'Green'
          },
          {
            label: `Pending: ${value.reduce((i, j) => i + Number(j.pendingcount), 0)}`,
            backgroundColor: 'Red',
            data: value.map(i => i.pendingcount),
            hoverBackgroundColor: 'Red'
          }
        ]
      };
    }
  }
};
</script>
