<template>
  <div>
    <h1 class="text-center page-title">{{ title }}</h1>
    <!-- filter and button -->
    <b-row class="table-top-wrapper mx-2 mb-2">
      <b-col sm class="px-0">
        <b-form inline>
          <div data-v-step="0">
            <b-form-select
              v-model="regionSelected"
              :options="regions"
              :class="{
                'box-shadow2 ml-2 mb-2': !isDarkMode,
                'box-shadow2 bg-dark text-light ml-2 mb-2': isDarkMode
              }"
            ></b-form-select>
            <b-form-select
              v-model="clusterSelected"
              :options="clusters"
              :class="{
                'box-shadow2 ml-2 mb-2': !isDarkMode,
                'box-shadow2 bg-dark text-light ml-2 mb-2': isDarkMode
              }"
            ></b-form-select>
            <b-form-select
              v-model="storeSelected"
              :options="stores"
              :class="{
                'box-shadow2 ml-2 mb-2': !isDarkMode,
                'box-shadow2 bg-dark text-light ml-2 mb-2': isDarkMode
              }"
            ></b-form-select>
            <b-button
              data-v-step="3"
              :class="{
                'box-shadow2 btn btn-success ml-2 mb-2': !isDarkMode,
                'btn btn-primary text-light ml-2 mb-2': isDarkMode
              }"
              @click="downloadMIS"
              ><font-awesome-icon class="mr-1" :icon="['fas', 'download']" /> Download MIS</b-button
            >
          </div>
          <b-col sm class="px-0">
            <b-form data-v-step="1" inline class="float-right">
              <b-input-group class="box-shadow2 ml-2 mb-2">
                <b-input-group-append>
                  <b-form-datepicker
                    sm
                    button-only
                    id="start-datepicker"
                    placeholder="Start Date"
                    :min="minDate"
                    :max="maxDate"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    v-model="startDateValue"
                  >
                  </b-form-datepicker>
                </b-input-group-append>
                <b-form-input
                  id="example-input"
                  v-model="startDateValue"
                  type="text"
                  placeholder="Start Date"
                  autocomplete="off"
                  :class="{
                    '': !isDarkMode,
                    'bg-dark text-light': isDarkMode
                  }"
                  disabled
                ></b-form-input>
              </b-input-group>
              <b-input-group class="box-shadow2 ml-2 mb-2">
                <b-input-group-append>
                  <b-form-datepicker
                    button-only
                    id="end-datepicker"
                    placeholder="End Date"
                    :min="minDate"
                    :max="maxDate"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    v-model="endDateValue"
                  ></b-form-datepicker>
                </b-input-group-append>
                <b-form-input
                  id="example-input"
                  v-model="endDateValue"
                  type="text"
                  placeholder="End Date"
                  autocomplete="off"
                  :class="{
                    '': !isDarkMode,
                    'bg-dark text-light ': isDarkMode
                  }"
                  disabled
                ></b-form-input>
              </b-input-group>

              <!-- <b-form-select v-model="chartSelected" :options="chartView" class="ml-2  mb-2"></b-form-select> -->
            </b-form>
          </b-col>
        </b-form>
      </b-col>
      <b-button
        data-v-step="2"
        :class="{
          'box-shadow2 btn btn-success ml-2 mb-2': !isDarkMode,
          'btn btn-primary text-light ml-2 mb-2': isDarkMode
        }"
        @click="showChart"
        ><font-awesome-icon class="mr-1" :icon="['fas', 'eye']" />Show Chart</b-button
      >
    </b-row>
    <!-- charts -->
    <!-- <b-row class="mt-5">
      <b-col>
        <h3>Report Submitted Wise :-</h3>
        <checklist-chart v-if="isChartVisible && chartSelected === 'region'" chart-type="region" :data="regionChart">
        </checklist-chart>
        <checklist-chart v-if="isChartVisible && chartSelected === 'cluster'" chart-type="cluster" :data="clusterChart">
        </checklist-chart>
        <checklist-chart v-if="chartSelected === 'store'" chart-type="storeid" :data="dashboardChart">
        </checklist-chart>
      </b-col>
    </b-row> -->
    <b-row
      :class="{
        'box-shadow2 mx-2 text-dark': !isDarkMode,
        'box-shadow2 dark-div mx-2 text-light': isDarkMode
      }"
    >
      <b-col lg="12" sm="12">
        <b-carousel
          id="carousel-1"
          :class="{ lightCarousel: !isDarkMode }"
          v-model="slide"
          :interval="4000"
          no-touch
          controls
          indicators
          img-width="1024"
          img-height="320"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <b-carousel-slide img-blank>
            <h3 :class="{ 'text-dark': !isDarkMode, 'text-light': isDarkMode }">Total Users</h3>
            <total-user :data="activeReport"></total-user>
          </b-carousel-slide>
          <b-carousel-slide img-blank>
            <h3 :class="{ 'text-dark': !isDarkMode, 'text-light': isDarkMode }">Active Users</h3>
            <active-user class="totalUser" :data="rolesReport"></active-user>
          </b-carousel-slide>
          <b-carousel-slide img-blank>
            <h3 :class="{ 'text-dark': !isDarkMode, 'text-light': isDarkMode }">inActive Users</h3>
            <in-active-user class="totalUser" :data="rolesReport"></in-active-user>
          </b-carousel-slide>
          <b-carousel-slide img-blank>
            <h3 :class="{ 'text-dark': !isDarkMode, 'text-light': isDarkMode }">ChecklistName Wise</h3>
            <checklist-name-chart class="totalUser" :data="nameChart"> </checklist-name-chart>
          </b-carousel-slide>
        </b-carousel>
      </b-col>
    </b-row>
    <div class="responsive-charts">
      <b-row
        :class="{
          'box-shadow2 mx-2 mb-3 text-center text-dark': !isDarkMode,
          'box-shadow2 dark-div mx-2 mb-3 text-center text-center text-light': isDarkMode
        }"
      >
        <b-col>
          <h3 :class="{ 'text-dark': !isDarkMode, 'text-light': isDarkMode }">Total Users</h3>
          <total-user class="totalUser" :data="activeReport"></total-user>
        </b-col>
      </b-row>
      <b-row
        :class="{
          'box-shadow2 mx-2 mb-3 text-center text-dark': !isDarkMode,
          'box-shadow2 dark-div mx-2 mb-3 text-center text-light': isDarkMode
        }"
      >
        <b-col>
          <h3 :class="{ 'text-dark': !isDarkMode, 'text-light': isDarkMode }">Active Users</h3>
          <active-user class="totalUser" :data="rolesReport"></active-user>
        </b-col>
      </b-row>
      <b-row
        :class="{
          'box-shadow2 mx-2 mb-3 text-center text-dark': !isDarkMode,
          'box-shadow2 dark-div mx-2 mb-3 text-center text-light': isDarkMode
        }"
      >
        <b-col>
          <h3 :class="{ 'text-dark': !isDarkMode, 'text-light': isDarkMode }">inActive Users</h3>
          <in-active-user class="totalUser" :data="rolesReport"></in-active-user>
        </b-col>
      </b-row>
      <b-row
        :class="{
          'box-shadow2 mx-2 text-center text-dark': !isDarkMode,
          'box-shadow2 dark-div mx-2 text-center text-light': isDarkMode
        }"
      >
        <b-col>
          <h3 :class="{ 'text-dark': !isDarkMode, 'text-light': isDarkMode }">ChecklistName Wise</h3>
          <checklist-name-chart class="totalUser" :data="nameChart"> </checklist-name-chart>
        </b-col>
      </b-row>
    </div>

    <!-- <v-tour name="myTour" :steps="steps" :callbacks="callbacks"></v-tour> -->
  </div>
</template>

<script>
import moment from 'moment';
import router from '@/router';
import { mapState, mapActions } from 'vuex';
import ChecklistChart from '../../components/Charts/ChecklistChart.vue';
import ChecklistNameChart from '../../components/Charts/ChecklistNameChart.vue';
import InActiveUser from '../../components/Charts/InActiveUser.vue';
import ActiveUser from '../../components/Charts/ActiveUser.vue';
import TotalUser from '../../components/Charts/TotalUser.vue';

export default {
  name: 'Dashboard',
  metaInfo() {
    return {
      title: 'Dashboard',
      meta: [
        {
          name: 'description',
          content: `See Charts`
        }
      ]
    };
  },
  components: { ChecklistChart, ChecklistNameChart, InActiveUser, ActiveUser, TotalUser },
  data() {
    return {
      slide: 0,
      sliding: null,
      callbacks: {
        onFinish() {
          window.localStorage.setItem('hasCompletedDashboard', 'true');
        }
      },
      steps: [
        {
          target: '[data-v-step="0"]', // We're using document.querySelector() under the hood
          content: `Select the Store according to the Region and Cluster.`,
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="1"]',
          content: 'Select the Start Date ,End Date and Chart View.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="2"]',
          content: 'Click on Show Chart to see the Chart.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="3"]', // We're using document.querySelector() under the hood
          content: `Download the MIS as per Selected criteria.`,
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        }
      ],
      title: 'Dashboard',
      isChartVisible: false,
      stores: [{ text: 'All Stores', value: 'all' }],
      regions: [{ text: 'All Regions', value: 'all' }],
      clusters: [{ text: 'All Clusters', value: 'all' }],
      chartView: [
        { text: 'Store Wise', value: 'store' },
        { text: 'Region Wise', value: 'region' },
        { text: 'Cluster Wise', value: 'cluster' }
      ],
      regionSelected: 'all',
      clusterSelected: 'all',
      storeSelected: 'all',
      chartSelected: 'store',
      startDateValue: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      endDateValue: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      minDate: moment('2020-01-01').format('YYYY-MM-DD'),
      maxDate: moment().subtract(1, 'days').format('YYYY-MM-DD')
    };
  },
  mounted() {
    this.downloadReport();
    this.reportByDesignation({ router });
    this.reportByRoles({ router });
    this.listStore({ router });
    this.listCluster({ router, query: { type: 'cluster' } });
    this.listRegion({ router, query: { type: 'region' } });
    this.chartListFirst({
      router,
      query: {
        startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
        enddate: moment(this.endDateValue).format('YYYY-MM-DD')
      }
    });
    this.chartListSecond({
      router,
      query: {
        startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
        enddate: moment(this.endDateValue).format('YYYY-MM-DD')
      }
    });
  },
  methods: {
    ...mapActions('mis', ['download']),
    ...mapActions('dashboard', ['chartListFirst']),
    ...mapActions('dashChart', ['chartListSecond']),
    ...mapActions('store', ['listStore', 'listCluster', 'listRegion', 'listByUser']),
    ...mapActions('user', ['reportByDesignation', 'downloadReport', 'reportByRoles']),
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    chartDashboard() {
      let storesList = [];
      if (this.storeSelected === 'all') {
        storesList = this.stores.map(s => s.value);
        storesList.shift();
      } else {
        storesList = this.storeSelected;
      }
      this.chartListFirst({
        query: {
          startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
          enddate: moment(this.endDateValue).format('YYYY-MM-DD'),
          stores: storesList
        }
      });
      this.chartListSecond({
        query: {
          startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
          enddate: moment(this.endDateValue).format('YYYY-MM-DD')
        }
      });
    },
    downloadMIS() {
      let storesList = [];
      if (this.storeSelected === 'all') {
        storesList = this.stores.map(s => s.value);
        storesList.shift();
      } else {
        storesList = this.storeSelected;
      }
      this.download({
        query: {
          startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
          enddate: moment(this.endDateValue).format('YYYY-MM-DD'),
          stores: storesList
        }
      });
    },
    showChart() {
      this.chartDashboard();
      this.isChartVisible = true;
    },
    calculateRegionWise(data) {
      let regionWise = {};
      data.forEach(d => {
        const region = d.region;
        const Count = d.count;
        const submitted = d.submittedCount === '' ? 0 : d.submittedCount;
        const pending = d.pendingCount === '' ? 0 : d.pendingCount;
        if (regionWise[region]) {
          regionWise[region].submittedCount += submitted;
          regionWise[region].pendingCount += pending;
          regionWise[region].count += Count;
        } else {
          regionWise[region] = {
            submittedCount: submitted,
            pendingCount: pending,
            count: Count,
            region: d.region
          };
        }
      });
      return Object.values(regionWise);
    },
    calculateClusterWise(data) {
      let clusterWise = {};
      data.forEach(d => {
        const cluster = d.cluster;
        const Count = d.count;
        const submitted = d.submittedCount === '' ? 0 : d.submittedCount;
        const pending = d.pendingCount === '' ? 0 : d.pendingCount;
        if (clusterWise[cluster]) {
          clusterWise[cluster].submittedCount += submitted;
          clusterWise[cluster].pendingCount += pending;
          clusterWise[cluster].count += Count;
        } else {
          clusterWise[cluster] = {
            submittedCount: submitted,
            pendingCount: pending,
            count: Count,
            cluster: d.cluster
          };
        }
      });
      return Object.values(clusterWise);
    }
  },
  computed: {
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('mis', ['misList']),
    ...mapState('dashChart', ['nameChart']),
    ...mapState('dashboard', ['dashboardChart']),
    ...mapState('store', ['storeList', 'clusterList', 'regionList', 'storeByUser']),
    ...mapState('user', ['pieChart', 'activeReport', 'rolesReport']),
    regionChart() {
      return this.calculateRegionWise(this.dashboardChart);
    },
    clusterChart() {
      return this.calculateClusterWise(this.dashboardChart);
    },
    handleLegendColor() {
      return this.isDarkMode ? 'white' : 'black';
    }
  },

  watch: {
    regionSelected(value) {
      if (value === 'all') {
        this.listStore({ router });
        this.listCluster({ router, query: { type: 'cluster' } });
      } else {
        this.listStore({ router, query: { by: 'region', value } });
        this.listCluster({ router, query: { type: 'cluster', by: 'region', value } });
      }
    },
    clusterSelected(value) {
      if (value === 'all') {
        this.listStore({ router });
      } else {
        this.listStore({ router, query: { by: 'cluster', value } });
      }
    },
    clusterList(newValue) {
      this.clusters = [{ text: 'All Clusters', value: 'all' }];
      this.clusters = [...this.clusters, ...newValue];
    },
    regionList(newValue) {
      this.regions = [{ text: 'All Regions', value: 'all' }];
      this.regions = [...this.regions, ...newValue];
    },
    storeList(newValue) {
      this.stores = [{ text: 'All Stores', value: 'all' }];
      const temp = newValue.map(store => ({ text: store.storeid, value: store.storeid }));
      this.stores = [...this.stores, ...temp];
    },
    misList(newValue) {
      if (newValue.length) {
        let csv = `,,,,,MIS from ${moment(this.startDateValue).format('DD-MM-YYYY')} to ${moment(
          this.endDateValue
        ).format('DD-MM-YYYY')}\n`;
        const processedValue = newValue;
        csv += 'Row Number,Region,Cluster,Store Id,Store Name, Checklist Name, Frequency,Format,Submitted By, Status\n';
        processedValue.forEach(row => {
          Object.values(row).forEach(val => {
            csv += `"${val}"`;
            csv += ',';
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'MIS.csv';
        anchor.click();
      }
    }
  }
};
</script>
<style>
.totalUser {
  height: 400px;
}
.responsive-charts {
  display: none;
}
@media all and (max-width: 1023px) {
  .responsive-charts {
    display: block;
  }
  #carousel-1 {
    display: none;
  }
}
</style>
