<script>
import { Pie } from 'vue-chartjs';

export default {
  extends: Pie,
  props: ['data'],

  watch: {
    data(value) {
      const allUsers = value.map(item => item.totalUsers);
      const activeUsers = value.map(item => item.activeUsers);
      const inActiveUsers = value.map(item => item.inActiveUsers);

      this.renderChart(
        {
          labels: ['Active Users : ' + activeUsers, 'inActive Users : ' + inActiveUsers],
          datasets: [
            {
              backgroundColor: ['#90EE90', '#FF160C'],
              data: [...activeUsers, ...inActiveUsers]
            }
          ]
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            labels: {
              fontColor: 'grey'
            }
          }
        }
      );
    }
  }
};
</script>
