var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"text-center page-title"},[_vm._v(_vm._s(_vm.title))]),_c('b-row',{staticClass:"table-top-wrapper mx-2 mb-2"},[_c('b-col',{staticClass:"px-0",attrs:{"sm":""}},[_c('b-form',{attrs:{"inline":""}},[_c('div',{attrs:{"data-v-step":"0"}},[_c('b-form-select',{class:{
              'box-shadow2 ml-2 mb-2': !_vm.isDarkMode,
              'box-shadow2 bg-dark text-light ml-2 mb-2': _vm.isDarkMode
            },attrs:{"options":_vm.regions},model:{value:(_vm.regionSelected),callback:function ($$v) {_vm.regionSelected=$$v},expression:"regionSelected"}}),_c('b-form-select',{class:{
              'box-shadow2 ml-2 mb-2': !_vm.isDarkMode,
              'box-shadow2 bg-dark text-light ml-2 mb-2': _vm.isDarkMode
            },attrs:{"options":_vm.clusters},model:{value:(_vm.clusterSelected),callback:function ($$v) {_vm.clusterSelected=$$v},expression:"clusterSelected"}}),_c('b-form-select',{class:{
              'box-shadow2 ml-2 mb-2': !_vm.isDarkMode,
              'box-shadow2 bg-dark text-light ml-2 mb-2': _vm.isDarkMode
            },attrs:{"options":_vm.stores},model:{value:(_vm.storeSelected),callback:function ($$v) {_vm.storeSelected=$$v},expression:"storeSelected"}}),_c('b-button',{class:{
              'box-shadow2 btn btn-success ml-2 mb-2': !_vm.isDarkMode,
              'btn btn-primary text-light ml-2 mb-2': _vm.isDarkMode
            },attrs:{"data-v-step":"3"},on:{"click":_vm.downloadMIS}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'download']}}),_vm._v(" Download MIS")],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"sm":""}},[_c('b-form',{staticClass:"float-right",attrs:{"data-v-step":"1","inline":""}},[_c('b-input-group',{staticClass:"box-shadow2 ml-2 mb-2"},[_c('b-input-group-append',[_c('b-form-datepicker',{attrs:{"sm":"","button-only":"","id":"start-datepicker","placeholder":"Start Date","min":_vm.minDate,"max":_vm.maxDate,"date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' }},model:{value:(_vm.startDateValue),callback:function ($$v) {_vm.startDateValue=$$v},expression:"startDateValue"}})],1),_c('b-form-input',{class:{
                  '': !_vm.isDarkMode,
                  'bg-dark text-light': _vm.isDarkMode
                },attrs:{"id":"example-input","type":"text","placeholder":"Start Date","autocomplete":"off","disabled":""},model:{value:(_vm.startDateValue),callback:function ($$v) {_vm.startDateValue=$$v},expression:"startDateValue"}})],1),_c('b-input-group',{staticClass:"box-shadow2 ml-2 mb-2"},[_c('b-input-group-append',[_c('b-form-datepicker',{attrs:{"button-only":"","id":"end-datepicker","placeholder":"End Date","min":_vm.minDate,"max":_vm.maxDate,"date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' }},model:{value:(_vm.endDateValue),callback:function ($$v) {_vm.endDateValue=$$v},expression:"endDateValue"}})],1),_c('b-form-input',{class:{
                  '': !_vm.isDarkMode,
                  'bg-dark text-light ': _vm.isDarkMode
                },attrs:{"id":"example-input","type":"text","placeholder":"End Date","autocomplete":"off","disabled":""},model:{value:(_vm.endDateValue),callback:function ($$v) {_vm.endDateValue=$$v},expression:"endDateValue"}})],1)],1)],1)],1)],1),_c('b-button',{class:{
        'box-shadow2 btn btn-success ml-2 mb-2': !_vm.isDarkMode,
        'btn btn-primary text-light ml-2 mb-2': _vm.isDarkMode
      },attrs:{"data-v-step":"2"},on:{"click":_vm.showChart}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'eye']}}),_vm._v("Show Chart")],1)],1),_c('b-row',{class:{
      'box-shadow2 mx-2 text-dark': !_vm.isDarkMode,
      'box-shadow2 dark-div mx-2 text-light': _vm.isDarkMode
    }},[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-carousel',{class:{ lightCarousel: !_vm.isDarkMode },attrs:{"id":"carousel-1","interval":4000,"no-touch":"","controls":"","indicators":"","img-width":"1024","img-height":"320"},on:{"sliding-start":_vm.onSlideStart,"sliding-end":_vm.onSlideEnd},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},[_c('b-carousel-slide',{attrs:{"img-blank":""}},[_c('h3',{class:{ 'text-dark': !_vm.isDarkMode, 'text-light': _vm.isDarkMode }},[_vm._v("Total Users")]),_c('total-user',{attrs:{"data":_vm.activeReport}})],1),_c('b-carousel-slide',{attrs:{"img-blank":""}},[_c('h3',{class:{ 'text-dark': !_vm.isDarkMode, 'text-light': _vm.isDarkMode }},[_vm._v("Active Users")]),_c('active-user',{staticClass:"totalUser",attrs:{"data":_vm.rolesReport}})],1),_c('b-carousel-slide',{attrs:{"img-blank":""}},[_c('h3',{class:{ 'text-dark': !_vm.isDarkMode, 'text-light': _vm.isDarkMode }},[_vm._v("inActive Users")]),_c('in-active-user',{staticClass:"totalUser",attrs:{"data":_vm.rolesReport}})],1),_c('b-carousel-slide',{attrs:{"img-blank":""}},[_c('h3',{class:{ 'text-dark': !_vm.isDarkMode, 'text-light': _vm.isDarkMode }},[_vm._v("ChecklistName Wise")]),_c('checklist-name-chart',{staticClass:"totalUser",attrs:{"data":_vm.nameChart}})],1)],1)],1)],1),_c('div',{staticClass:"responsive-charts"},[_c('b-row',{class:{
        'box-shadow2 mx-2 mb-3 text-center text-dark': !_vm.isDarkMode,
        'box-shadow2 dark-div mx-2 mb-3 text-center text-center text-light': _vm.isDarkMode
      }},[_c('b-col',[_c('h3',{class:{ 'text-dark': !_vm.isDarkMode, 'text-light': _vm.isDarkMode }},[_vm._v("Total Users")]),_c('total-user',{staticClass:"totalUser",attrs:{"data":_vm.activeReport}})],1)],1),_c('b-row',{class:{
        'box-shadow2 mx-2 mb-3 text-center text-dark': !_vm.isDarkMode,
        'box-shadow2 dark-div mx-2 mb-3 text-center text-light': _vm.isDarkMode
      }},[_c('b-col',[_c('h3',{class:{ 'text-dark': !_vm.isDarkMode, 'text-light': _vm.isDarkMode }},[_vm._v("Active Users")]),_c('active-user',{staticClass:"totalUser",attrs:{"data":_vm.rolesReport}})],1)],1),_c('b-row',{class:{
        'box-shadow2 mx-2 mb-3 text-center text-dark': !_vm.isDarkMode,
        'box-shadow2 dark-div mx-2 mb-3 text-center text-light': _vm.isDarkMode
      }},[_c('b-col',[_c('h3',{class:{ 'text-dark': !_vm.isDarkMode, 'text-light': _vm.isDarkMode }},[_vm._v("inActive Users")]),_c('in-active-user',{staticClass:"totalUser",attrs:{"data":_vm.rolesReport}})],1)],1),_c('b-row',{class:{
        'box-shadow2 mx-2 text-center text-dark': !_vm.isDarkMode,
        'box-shadow2 dark-div mx-2 text-center text-light': _vm.isDarkMode
      }},[_c('b-col',[_c('h3',{class:{ 'text-dark': !_vm.isDarkMode, 'text-light': _vm.isDarkMode }},[_vm._v("ChecklistName Wise")]),_c('checklist-name-chart',{staticClass:"totalUser",attrs:{"data":_vm.nameChart}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }