<script>
import { Pie } from 'vue-chartjs';

export default {
  extends: Pie,
  props: ['data'],

  watch: {
    data(value) {
      let randomColors = function () {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      };
      const activeData = value.map(report => report.ActiveUser);
      const filteredData = activeData.filter(item => item !== undefined).flat();
      const roles = filteredData.map(i => i.ListOfEmployeesActiveToday.map(j => j));
      const uniqueRoles = new Set(roles.flat());
      const roleWiseCount = [];
      uniqueRoles.forEach(i => {
        const count = roles.flat().filter(j => j === i).length;
        roleWiseCount.push({
          label: i,
          value: count,
          color: randomColors()
        });
      });
      this.renderChart(
        {
          labels: roleWiseCount.map(i => i.label + '  :  ' + i.value),
          datasets: [
            {
              backgroundColor: randomColors,
              data: roleWiseCount.map(i => i.value)
            }
          ]
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            labels: {
              fontColor: 'grey'
            }
          }
        }
      );
    }
  }
};
</script>
